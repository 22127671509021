import { createContext, useEffect, useRef, useState } from "react"
import moment from "moment"
import { UserService } from "../../views/users/service"
import jwt from '@src/auth/jwt/useJwt' // import for react render this after jwt is setted

export const TrialContext = createContext(null)

export const Trial = ({children}) => {  
    const [isTrialExpired, setIsTrialExpired] = useState(null)
    const cleanup = () => { 
        setIsTrialExpired(null)
    }

    const isMounted = useRef(true)

    const fetchIsTrialExpired = async() => {
        if (isTrialExpired === null) {
            const authenticatedUser = JSON.parse(localStorage.getItem("userData"))
            
            const user = authenticatedUser && (await UserService.findById(authenticatedUser.user_id))?.data
            
            if (user?.is_trial) {
                const createdAt = moment(user.createdAt)
                const hasExpired = Math.ceil(moment.duration(createdAt.diff(moment())).asDays() + 7) <= 0
                if (isMounted.current) {
                    setIsTrialExpired(hasExpired)
                }
            }
        }
    }

    useEffect(async () => {
        isMounted.current = true
        fetchIsTrialExpired()
        return () => {
          isMounted.current = false
        }
    }, [])
    

    return (
        <TrialContext.Provider value={{fetchIsTrialExpired, isTrialExpired, cleanup}}>
            {children}
        </TrialContext.Provider>
    )
}