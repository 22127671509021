//import useJwt from '@src/@core/auth/jwt/useJwt'

import jwtConfig from './jwtConfig'
import JwtService from './jwtService'

const useJwt = (config) => {
    const jwt = new JwtService(config)
  
    return {
      jwt
    }
  }

const { jwt } = useJwt(jwtConfig)

export default jwt
