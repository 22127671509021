import axios from 'axios'
import env from "react-dotenv"

const endpoint = `${env.CONECTA_GABINETE_API}/users`

export const UserService = {

    getAll() {
        return axios.get(endpoint)
    },

    findById(id) {
        return axios.get(`${endpoint}/${id}`)
    },

    create(data) {
        return axios.post(`${endpoint}`, data)
    },

    update(id, data) {
        return axios({
            method: 'put',
            url: `${endpoint}/${id}`,
            data,
            headers: { "Content-Type": "multipart/form-data" }
        })
    },

    destroy(id) {
        return axios.delete(`${endpoint}/${id}`)
    },

    resetPassword(data) {
        return axios.put(`${env.CONECTA_GABINETE_API}/reset-password`, data)
    },

    updatePassword(data) {
        return axios.patch(`${endpoint}/password`, data)
    },
    
    validateEmail(token) {
        return axios.patch(`${endpoint}/validate-email/${token}`)
    },

    resetVoters(id) {
        return axios.patch(`${endpoint}/${id}/reset/voters`)
    },

    resetDemands(id) {
        return axios.patch(`${endpoint}/${id}/reset/demands`)
    },

    resetOccurrences(id) {
        return axios.patch(`${endpoint}/${id}/reset/occurrences`)
    }

}