import { createContext, useEffect, useMemo, useState } from "react"
import { UserService } from "../../views/users/service"

export const UserContext = createContext(null)

export const UsersProviders = ({children}) => {
    const [users, setUsers] = useState([])

    const responsiblesOptions = useMemo(() => users.map((obj) => {
        return { value: obj._id, label: obj.name }
    }))

    useEffect(() => {
        UserService.getAll().then((response) => {
            setUsers(response.data)
        })
    }, [])

    return (
        <UserContext.Provider value={{users, responsiblesOptions}}>
            {children}
        </UserContext.Provider> 
    )
}

